import React from "react";
import "./customProjImage.css";
import { Edit, Close } from "@material-ui/icons";

const CustomProjImage = ({
  editing,
  obj,
  ind,
  setObj,
  setTick,
  name,
  networkUrl,
  onDelete = () => {},
}) => {
  const handleImage = (e) => {
    if (!e.target?.files?.[0]) return;
    var tempArr = [...obj];
    var tempObj = { ...tempArr[ind] };
    tempObj[`${name}`] = URL.createObjectURL(e.target.files[0]);
    tempObj[`${name}Obj`] = e.target?.files?.[0];
    tempArr[ind] = tempObj;
    setObj(tempArr);
    setTick((i) => i + 1);
  };

  const removeImage = () => {
    var tempArr = [...obj];
    var tempObj = { ...tempArr[ind] };
    tempObj[`${name}`] = networkUrl;
    delete tempObj[`${name}Obj`];
    tempArr[ind] = tempObj;
    setObj(tempArr);
    setTick((i) => i + 1);
    onDelete(ind);
  };

  return (
    <div
      style={{ display: "flex", marginBottom: 20, marginRight: 5 }}
      id="customProjImage"
    >
      <img
        src={obj[ind][`${name}`]}
        width={450}
        height={300}
        style={{ borderRadius: 10, marginBottom: 10 }}
      />
      {editing && (
        <>
          <input
            type="file"
            name="file"
            accept="image/*"
            onChange={handleImage}
            style={{ display: "none" }}
            id={`imgPicker${ind}`}
          />
          <label for={`imgPicker${ind}`} className="editIconWrapper">
            <Edit fontSize="medium" className="editIcon" />
          </label>
        </>
      )}
      {editing && (
        <div className="deleteIconWrapper" onClick={removeImage}>
          <Close fontSize="medium" className="closeIcon" />
        </div>
      )}
    </div>
  );
};

export default CustomProjImage;
