import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/User";
import CustomImage from "../../../components/customImage/customImage";
import CustomText from "../../../components/customText/customText";
import Qna from "../../../components/qna/qna";
import "../adminTabs.css";
import { Button } from "@mui/material";
import { storage, firestoreDb } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AdminContact = () => {
  const { contactData, refetchData } = useContext(UserContext);
  const [editing, setEditing] = useState(0);
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [, setTick] = useState(0);

  useEffect(() => {
    if (contactData?.sectionOne) setSectionOne({ ...contactData?.sectionOne });
    if (contactData?.sectionTwo) setSectionTwo({ ...contactData?.sectionTwo });
    setTick((i) => i + 1);
  }, [contactData]);

  const submitHandler = (data, objKey, imgKey) => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempObj = {};
      tempObj[objKey] = data?.length ? [...data] : { ...data };
      const id = contactData?.id;
      const docRef = doc(firestoreDb, "Contact", id);
      if (imgKey && data?.[imgKey] != contactData?.[objKey]?.[imgKey]) {
        const storageRef = ref(
          storage,
          `/contact/${id}/${data?.[`${imgKey}Obj`]?.name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          data?.[`${imgKey}Obj`]
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => {
            setShowLoader(false);
            console.log(err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              tempObj[objKey][imgKey] = url;
              delete tempObj[objKey][`${imgKey}Obj`];
              updateDoc(docRef, tempObj)
                .then(() => {
                  setShowLoader(false);
                  refetchData();
                  alert("Success");
                })
                .catch((err) => {
                  setShowLoader(false);
                  console.log("err", err);
                });
            });
          }
        );
      } else {
        updateDoc(docRef, tempObj)
          .then(() => {
            refetchData();
            setShowLoader(false);
            alert("Success");
          })
          .catch((err) => {
            setShowLoader(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div id="common">
      <div className="wrapper">
        <p className="title">Section One</p>
        <CustomImage
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="bannerUrl"
          networkUrl={contactData?.sectionOne?.bannerUrl}
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="title"
          className="subTitle"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text"
          className="subTitle"
        />
        <Button
          variant="contained"
          color={editing == 1 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionOne, "sectionOne", "bannerUrl")
              : () => setEditing(1)
          }
        >
          {editing == 1 ? "Save" : "Edit"}
        </Button>
        {editing == 1 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionOne(contactData?.sectionOne);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="wrapper">
        <p className="title">Section Two</p>
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="title"
          className="subTitle"
          placeholder="Enter title"
        />
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="subtitle"
          className="text"
          placeholder="Enter subtitle"
        />
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="text1"
          className="text"
          placeholder="Enter text1"
        />
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="text2"
          className="text"
          placeholder="Enter text2"
        />
        <Button
          variant="contained"
          color={editing == 2 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionTwo, "sectionTwo", "")
              : () => setEditing(2)
          }
        >
          {editing == 2 ? "Save" : "Edit"}
        </Button>
        {editing == 2 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionTwo(contactData?.sectionTwo);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminContact;
