import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import "./getEstimate.css";
import { UserContext } from "../../context/User";
function GetEstimate() {
  const { homeData } = useContext(UserContext);
  const { sectionFive } = homeData;
  return (
    <>
      <section
        class="section_footer"
        style={{ backgroundImage: `url(${sectionFive?.bannerUrl})` }}
      >
        <div className="section_footer_subDiv">
          <div className="section_footer_left">
            <h3>{sectionFive?.title}</h3>
            <p>{sectionFive?.text}</p>
          </div>
          <div className="section_footer_right">
            <a href="">Get A Free Estimate</a>
            <a href="">
              <FontAwesomeIcon icon={faPhone} /> (405) 259-9998
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default GetEstimate;
