import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./serviceList.css";
import { UserContext } from "../../context/User";

const ServiceList = () => {
  const navigate = useNavigate();
  const { servicesArray } = useContext(UserContext);

  return (
    <section className="section7">
      <div className="section7_subDiv7">
        <div className="heading">
          <h3>Our Services</h3>
        </div>
        <div className="services">
          {servicesArray.map((item) => (
            <div className="section7_subDiv7_small  section7_subDiv7_small_comonClass">
              <div className="section7_subDiv7_sub">
                <img src={item.img}></img>
                <p className="para_heading_comon">{item.title}</p>
                <div className="desc_para_div">
                  <p>{item.text}</p>
                </div>
                <button onClick={() => navigate(`/services/${item.route}`)}>
                  LEARN MORE
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceList;
