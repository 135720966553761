import React, { useEffect } from "react";
import "./privacy.css";

function PrivacyScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="privacy-Main">
        <div className="privacy-sub">
          <h1 className="privacyh">Privacy Policy</h1>

          <p>
            Thank you for considering Roof66 for your roofing and construction
            needs. As a company, we understand the importance of privacy and the
            need to protect your personal information. This privacy policy
            outlines how we collect, use, and protect the information that we
            gather from our website users.
          </p>

          <h3 className="privacy_heading">Information We Collect </h3>

          <p>
            When you visit our website, we may collect information about you,
            such as your name, email address, phone number, and other personal
            information. We may also collect information about your device, such
            as your IP address, browser type, and operating system. We may
            collect this information through various means, including through
            forms that you fill out on our website, through cookies and other
            tracking technologies, and through other automated means.
          </p>

          <h3 className="privacy_heading">How We Use Your Information</h3>

          <p>
            We use the information that we collect from our website users to
            provide you with the products and services that you have requested,
            to communicate with you about your requests, and to improve our
            website and the services that we offer. We may also use your
            information to send you promotional offers, newsletters, and other
            marketing materials. If you no longer wish to receive these
            communications, you can opt-out by following the instructions
            provided in the communication.
          </p>

          <h3 className="privacy_heading">How We Protect Your Information</h3>

          <p>
            We take the security of your personal information seriously and have
            implemented reasonable physical, technical, and administrative
            safeguards to protect your information from unauthorized access,
            use, and disclosure. However, despite our best efforts, no security
            measures are perfect, and we cannot guarantee the security of your
            information.
          </p>

          <h3 className="privacy_heading">Sharing Your Information</h3>

          <p>
            We do not sell or share your personal information with third parties
            for their own marketing purposes. However, we may share your
            information with our service providers and business partners who
            help us provide our products and services to you. We may also share
            your information with government agencies or other third parties if
            we are required to do so by law or if we believe that such
            disclosure is necessary to protect our rights or the safety of our
            users.
          </p>

          <h3 className="privacy_heading">Changes to Our Privacy Policy</h3>

          <p>
            We may update this privacy policy from time to time, and we
            encourage you to review it periodically. If we make material changes
            to this policy, we will notify you by posting a notice on our
            website or by other means.
          </p>

          <h3 className="privacy_heading">Contact Us</h3>

          <p>
            If you have any questions or concerns about our privacy policy or
            our use of your personal information, please contact us using the
            contact information provided on our website
          </p>
        </div>
      </section>
    </>
  );
}

export default PrivacyScreen;
