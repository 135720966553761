import React from "react";
import "./intro.css";

const Intro = ({ title, subtitle, img }) => {
  return (
    <>
      <section className="section9" style={{ backgroundImage: `url(${img})` }}>
        <div className="section9_subDiv9">
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
        </div>
      </section>
    </>
  );
};

export default Intro;
