import React, { useState } from "react";
import logo1 from "./image/logo black.jpg";
import { CgMenu, CgClose } from "react-icons/cg";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <section className="section2">
        <div className="section2_subDiv">
          <div className="imgDiv">
            <Link to="/">
              <img src={logo1} alt=""></img>
            </Link>
          </div>

          <div className="ul-Div">
            <div className="openMenu" onClick={() => setIsOpen(!isOpen)}>
              <CgMenu></CgMenu>
            </div>
            <ul
              className="block-menu"
              style={{
                left: isOpen ? "0px" : "",
              }}
            >
              <div className="closemenu" onClick={() => setIsOpen(false)}>
                <CgClose></CgClose>
              </div>
              {[
                {
                  name: "About",
                  link: "/about",
                },
                {
                  name: "Service ▼",
                  link: "/services",
                  child: [
                    {
                      child_name: "Residential Roofing",
                      child_link: "/services/residential-roofing",
                    },
                    {
                      child_name: "Commercial Roofing",
                      child_link: "/services/commercial-roofing",
                    },
                    {
                      child_name: "Roof Repair",
                      child_link: "/services/roof-repair",
                    },
                    {
                      child_name: "Skylights",
                      child_link: "/services/skylights",
                    },
                    {
                      child_name: "Home Remodeling",
                      child_link: "/services/home-remodelling",
                    },
                    {
                      child_name: "Storm Damage",
                      child_link: "/services/storm-damage",
                    },
                  ],
                },
                {
                  name: "Projects",
                  // link: "/projects"
                  link: "/projects",
                },
                {
                  name: "Financing",
                  link: "/financing",
                },
                {
                  name: "Refer Us",
                  link: "/referus",
                },
                {
                  name: "Contact",
                  link: "/contact",
                },
              ].map((item, index) => {
                return (
                  <div className="box_main">
                    <li className="list" key={index}>
                      <Link
                        className="box_main_link"
                        to={item.link}
                        // target="_blank"
                        onMouseEnter={(e) => {
                          e.target.classList.add("item");
                          setTimeout(() => {
                            e.target.classList.remove("item");
                          }, 2000);
                        }}
                      >
                        <span> </span>

                        <div> {item.name}</div>
                      </Link>

                      {item.child != undefined && item.child.length != 0 ? (
                        <div className="dropDown-Box " id="dropDown_main">
                          <div id="service_child_main_box">
                            {item.child.map((c) => {
                              return (
                                <span id="service_child_span">
                                  <Link
                                    className="Dropdown_child "
                                    id="service_child_link"
                                    to={c.child_link}
                                  >
                                    {c.child_name}
                                  </Link>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  </div>
                );
              })}
            </ul>
            <button
              className="headerButton"
              onClick={() => navigate("/contact")}
            >
              <span className="headerButton-span">GET A FREE ESTIMATE</span>
            </button>
          </div>
        </div>
      </section>
      {/* bottom footer is here 291 */}

      {/* <BotomFooter></BotomFooter> */}

      {/* copyright div 298  */}

      {/* <CopyrightFooter></CopyrightFooter> */}
    </>
  );
}

export default Navbar;
