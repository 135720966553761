import React, { useContext } from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import Roof_logo from "./images/roof_logo_footer.png";
import badge181_25 from "./images/badge181_25@2x.png";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { UserContext } from "../../context/User";

function Footer() {
  const navigate = useNavigate();
  const { homeData } = useContext(UserContext);
  const { sectionSix } = homeData;
  return (
    <section className="botom_section_footer">
      <div className="botom_section_footer_subDiv">
        <div className="first_footer_div first_second_third_hei_wid_defalt">
          <div className="Img_footer_logo_center">
            <div className="Roof_logoImg">
              <img src={Roof_logo} width="1200" height="621"></img>
            </div>
          </div>

          <div>
            <p className="first_div_para">{sectionSix?.text}</p>
          </div>
          <div>
            <ul className="first_footerDiv_ul_policy">
              <li onClick={() => navigate("/privacypolicy")}>Privacy Policy</li>
            </ul>
          </div>
        </div>
        <div className="second_footer_div first_second_third_hei_wid_defalt">
          <div>
            <div>
              <h3 className="footer-heading-title footer-heading-style-defalt ">
                Contact Info
              </h3>
            </div>

            <div className="contect_info_box">
              <div className="contect_info_first_box contect_info_common_box ">
                <span className=" second_box_icon">
                  <i>
                    <LocationCityIcon />
                  </i>
                </span>
                <span className="contect_info_icon_text_common">
                  {sectionSix?.name}
                </span>
              </div>
              <div className="contect_info_second_box contect_info_common_box ">
                <span className=" second_box_icon">
                  <i>
                    <PlaceIcon />
                  </i>
                </span>
                <span className="contect_info_icon_text_common">
                  {sectionSix?.address}
                </span>
              </div>
              <div className="contect_info_third_box contect_info_common_box ">
                <span className=" second_box_icon">
                  <PhoneIcon />
                </span>
                <span className="contect_info_icon_text_common">
                  {sectionSix?.phone}
                </span>
              </div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
