import React, { useContext, useEffect, useState } from "react";
import InfoBar from "../../components/infoBar/infoBar";
import Navbar from "../../components/navbar/navbar";
import Intro from "./intro/intro";
import Form from "../../components/form/form";
import Reviews from "./reviews/reviews";
import ServiceList from "../../components/serviceList/serviceList";
import RoofingQna from "./roofingQna/roofingQna";
import GetEstimate from "../../components/getEstimate/getEstimate";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer/footer";
import { UserContext } from "../../context/User";

function Home() {
  const { homeData } = useContext(UserContext);

  return (
    <>
      <InfoBar phone={homeData?.contactInfo?.phone} />
      <Navbar />
      <Intro
        sectionOne={homeData?.sectionOne}
        phone={homeData?.contactInfo?.phone}
        bannerUrl={homeData?.sectionOne?.bannerUrl}
      />
      <Form sectionTwo={homeData?.sectionTwo} />
      <Reviews />
      <ServiceList />
      <RoofingQna qnaSection={homeData?.qnaSection} />
      <GetEstimate />
      <Footer />
      <Copyright />
    </>
  );
}

export default Home;
