import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../context/User";
import CustomImage from "../../../components/customImage/customImage";
import CustomText from "../../../components/customText/customText";
import "../adminTabs.css";
import { Button } from "@mui/material";
import { storage, firestoreDb } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import CustomProjImage from "../../../components/customProjImage/customProjImage";

const AdminProjects = () => {
  const { projectData, refetchData } = useContext(UserContext);
  const [editing, setEditing] = useState(0);
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [, setTick] = useState(0);

  const id = projectData?.id;

  useEffect(() => {
    if (projectData?.sectionOne) setSectionOne({ ...projectData?.sectionOne });
    if (projectData?.sectionTwo?.length)
      setSectionTwo([...projectData?.sectionTwo]);
    setTick((i) => i + 1);
  }, [projectData]);

  const updateDocHandler = (obj) => {
    const docRef = doc(firestoreDb, "Projects", id);
    updateDoc(docRef, obj)
      .then(() => {
        refetchData();
        setShowLoader(false);
        alert("Success");
      })
      .catch((err) => {
        setShowLoader(false);
        console.log("err", err);
      });
  };

  const submitHandler = (data, objKey, imgKey) => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempObj = {};
      tempObj[objKey] = data?.length ? [...data] : { ...data };
      if (imgKey && data?.[imgKey] != projectData?.[objKey]?.[imgKey]) {
        const storageRef = ref(
          storage,
          `/projects/${id}/${data?.[`${imgKey}Obj`]?.name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          data?.[`${imgKey}Obj`]
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => {
            setShowLoader(false);
            console.log(err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              tempObj[objKey][imgKey] = url;
              delete tempObj[objKey][`${imgKey}Obj`];
              updateDocHandler(tempObj);
            });
          }
        );
      } else {
        updateDocHandler(tempObj);
      }
    }
  };

  const submitImageHandler = () => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempArr = [];
      sectionTwo.map((item, ind) => {
        if (item?.imgObj) {
          const storageRef = ref(
            storage,
            `/projects/${id}/${item?.imgObj?.name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, item?.imgObj);
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {
              setShowLoader(false);
              console.log(err);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                tempArr.push({ img: url });
                console.log("1", tempArr);
                if (tempArr.length == sectionTwo.length) {
                  console.log("2", tempArr);
                  updateDocHandler({ sectionTwo: tempArr });
                }
              });
            }
          );
        } else {
          tempArr.push(item);
          console.log("3", tempArr);
          if (tempArr.length == sectionTwo.length) {
            console.log("4", tempArr);
            updateDocHandler({ sectionTwo: tempArr });
          }
        }
      });
    }
  };

  return (
    <div id="common">
      <div className="wrapper">
        <p className="title">Section One</p>
        <CustomImage
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="bannerUrl"
          networkUrl={projectData?.sectionOne?.bannerUrl}
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="title"
          className="subTitle"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text"
          className="subTitle"
        />
        <Button
          variant="contained"
          color={editing == 1 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionOne, "sectionOne", "bannerUrl")
              : () => setEditing(1)
          }
        >
          {editing == 1 ? "Save" : "Edit"}
        </Button>
        {editing == 1 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionOne(projectData?.sectionOne);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>
      <div className="wrapper">
        <p className="title">Section Two</p>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {sectionTwo.map((item, index) => (
            <CustomProjImage
              editing={editing == 2}
              obj={sectionTwo}
              setObj={setSectionTwo}
              setTick={setTick}
              name="img"
              networkUrl={item?.img}
              ind={index}
              onDelete={(i) => {
                var tempArr = [...sectionTwo];
                tempArr.splice(i, 1);
                setSectionTwo(tempArr);
                setTick((val) => val + 1);
              }}
            />
          ))}
        </div>

        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            var tempArr = [...sectionTwo];
            tempArr.push({ img: "" });
            setSectionTwo(tempArr);
            setEditing(2);
          }}
        >
          Add New
        </Button>
        {editing == 2 && (
          <>
            {!!sectionTwo?.length && (
              <Button
                variant="contained"
                color={"primary"}
                onClick={submitImageHandler}
                style={{ marginLeft: 10 }}
              >
                Save
              </Button>
            )}
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setEditing(0);
                setSectionTwo(projectData?.sectionTwo || []);
              }}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminProjects;
