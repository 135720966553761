import React from "react";
import "./customImage.css";
import { Edit, Close } from "@material-ui/icons";

const CustomImage = ({ editing, obj, setObj, setTick, name, networkUrl }) => {
  const handleImage = (e) => {
    if (!e.target?.files?.[0]) return;
    var tempObj = { ...obj };
    tempObj[`${name}`] = URL.createObjectURL(e.target.files[0]);
    tempObj[`${name}Obj`] = e.target?.files?.[0];
    setObj(tempObj);
    setTick((i) => i + 1);
  };

  const removeImage = () => {
    var tempObj = { ...obj };
    tempObj[`${name}`] = networkUrl;
    delete tempObj[`${name}Obj`];
    setObj(tempObj);
    setTick((i) => i + 1);
  };

  return (
    <div style={{ display: "flex" }} id="customImage">
      <img
        src={obj[`${name}`]}
        width={700}
        height={350}
        style={{ borderRadius: 10, marginBottom: 10 }}
      />
      {editing ? (
        obj[`${name}Obj`] ? (
          <div className="editIconWrapper" onClick={removeImage}>
            <Close fontSize="medium" className="closeIcon" />
          </div>
        ) : (
          <>
            <input
              type="file"
              name="file"
              accept="image/*"
              onChange={handleImage}
              style={{ display: "none" }}
              id="imgPicker"
            />
            <label for="imgPicker" className="editIconWrapper">
              <Edit fontSize="medium" className="editIcon" />
            </label>
          </>
        )
      ) : null}
    </div>
  );
};

export default CustomImage;
