import React, { useEffect, useRef, useState } from "react";
import "./customText.css";

const CustomText = ({
  obj,
  name,
  setObj,
  setTick,
  className,
  editing,
  placeholder,
}) => {
  const [inputText, setInputText] = useState(obj[`${name}`]);
  const inputRef = useRef();
  inputRef.current = inputText;

  useEffect(() => {
    if (editing)
      setTimeout(() => {
        if (inputRef.current == inputText) {
          var tempObj = { ...obj };
          tempObj[`${name}`] = inputText;
          setObj(tempObj);
          setTick((i) => i + 1);
        }
      }, 500);
  }, [inputText]);

  useEffect(() => {
    if (obj[`${name}`] != inputText) setInputText(obj[`${name}`]);
  }, [obj]);

  return (
    <div id="customText">
      {editing ? (
        <textarea
          style={{ backgroundColor: "#E7E6E4", marginBottom: 5 }}
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
          className="newsInput"
          placeholder={placeholder || "Enter text here"}
          name="bannerUrl"
        />
      ) : (
        <p className={className}>{obj[`${name}`]}</p>
      )}
    </div>
  );
};

export default CustomText;
