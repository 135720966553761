import React from "react";

import { useState } from "react";
import "./roofingQna.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function RoofingQna({ qnaSection = [] }) {
  const [openInd, setOpenInd] = useState(0);

  return (
    <section className="Questions_Answers_box_section">
      {" "}
      {/* width 100% box*/}
      <div className="Questions_Answers_container">
        {" "}
        {/* width 80% box*/}
        <div className="Most_Common_Roofing_Questions_heading">
          <h3>Most Common Roofing Questions & Answers</h3>
        </div>
        <div className="main_question_containar">
          {qnaSection.map((item, ind) => (
            <div className="firstquestion_center  quistion_box_defult_center">
              <div
                onClick={() => setOpenInd(openInd == ind ? -1 : ind)}
                className="first_question_icon question_defalte_style_question_icon"
              >
                <span>{item?.ques}</span>
                <span>
                  <i
                    className={
                      openInd == ind
                        ? "box_according_icon_open displayShow icon_hide"
                        : "box_according_icon_open displayHide"
                    }
                  >
                    <ArrowDropDownIcon />
                  </i>
                  <i
                    className={
                      openInd == ind
                        ? "box_according_icon_close displayShow"
                        : "box_according_icon_close displayHide icon_hide"
                    }
                  >
                    <ArrowDropUpIcon />
                  </i>
                </span>
              </div>
              <div>
                <div
                  id={openInd == ind ? "displayShow" : "displayHide"}
                  className="first_question_paragraf question_paragraf_in_default"
                >
                  <p>{item?.ans}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default RoofingQna;
