import React from "react";
import "./copyright.css";

function Copyright() {
  return (
    <div className="last_copyright_box">
      <p>Copyright © Roof66, Oklahoma</p>
    </div>
  );
}

export default Copyright;
