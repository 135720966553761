import React, { useContext } from "react";
import InfoBar from "../../components/infoBar/infoBar";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import BotomFooter from "../../components/getEstimate/getEstimate";
import Copyright from "../../components/copyright/copyright";
import ServiceList from "../../components/serviceList/serviceList";
import Intro from "../../components/intro/intro";
import { UserContext } from "../../context/User";

const OurServices = () => {
  const { servicesData } = useContext(UserContext);
  const { sectionOne } = servicesData;
  return (
    <>
      <InfoBar />
      <Navbar />
      <Intro
        title={sectionOne?.title}
        subtitle={sectionOne?.text}
        img={sectionOne?.bannerUrl}
      />
      <ServiceList />
      <BotomFooter />
      <Footer />
      <Copyright />
    </>
  );
};

export default OurServices;
