import React, { useContext } from "react";

import "./extraInfo.css";
import { UserContext } from "../../context/User";

const ExtraInfo = ({ data }) => {
  const { homeData, serviceDetailData } = useContext(UserContext);
  const imageArr = serviceDetailData?.sectionTwo?.length
    ? serviceDetailData.sectionTwo.map((item) => item?.img)
    : [];
  return (
    <div className="section2_finance">
      <div className="hero">
        <div>
          <h1 className="hero-title heading1">{data?.title}</h1>
          <div className="hero_para">
            <p>{data?.text1}</p>
            <p>{data?.text2}</p>
            <p>{data?.text3}</p>
          </div>
          <a href="#" className="button">
            {data?.btnText} ( {homeData?.sectionSix?.phone} )
          </a>
        </div>
        <div className="hero-images">
          {imageArr.map((url) => (
            <img src={url} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;
