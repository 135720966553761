import React, { useState, useEffect, useRef } from "react";
import { auth, firestoreDb } from "../firebase";
import { getDocs, collection } from "firebase/firestore";

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [homeData, setHomeData] = useState({});
  const [servicesData, setServicesData] = useState({});
  const [serviceDetailData, setServicesDetailData] = useState({});
  const [aboutData, setAboutData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [financeData, setFinanceData] = useState({});
  const [referData, setReferData] = useState({});
  const [contactData, setContactData] = useState({});
  const [refetch, setRefetch] = useState(0);

  const servicesArray = servicesData?.sectionTwo?.length
    ? servicesData.sectionTwo.map((item) => ({
        ...item,
        route: item.title.split(" ").join("-"),
      }))
    : [];

  const fetchData = async (collectionName, setter) => {
    try {
      const snap = await getDocs(collection(firestoreDb, collectionName));
      if (snap.docs.length)
        setter({ ...snap.docs[0].data(), id: snap.docs[0].id });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchData("Home", setHomeData);
    fetchData("Services", setServicesData);
    fetchData("About", setAboutData);
    fetchData("Projects", setProjectData);
    fetchData("Finance", setFinanceData);
    fetchData("Refer", setReferData);
    fetchData("Contact", setContactData);
    fetchData("ServiceDetail", setServicesDetailData);
  }, [refetch]);

  const userSubscriberRef = useRef();

  const onAuthStateChanged = async (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
      if (userSubscriberRef.current) userSubscriberRef.current();
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        homeData,
        servicesData,
        serviceDetailData,
        servicesArray,
        aboutData,
        projectData,
        financeData,
        referData,
        contactData,
        refetchData: () => setRefetch((i) => i + 1),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
