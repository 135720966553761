import React, { useState } from "react";
import "./estimateForm.css";
import { sendEmailHelper } from "../../helpers";

function EstimateForm({ title, subtitle, img, formTitle }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name || !phone || !address) return alert("Fill details");
    var data = {
      from_name: "random name",
      from_email: "randomemail@gmail.com",
      name,
      phone,
      email,
      address,
      service: title,
    };
    sendEmailHelper("template_z3yipzj", data);
  };

  return (
    <>
      <div className="Finance_mainBox">
        <div
          className="Finance_Img_Form"
          style={{ backgroundImage: `url(${img})` }}
        >
          <div className="Finance_up_img_left_right_box">
            <div className="left_posterUp">
              <div className="left_posterUp_heading_box">
                <h1>{title}</h1>
              </div>
              <div className="left_posterUp_para_box">
                <p>{subtitle}</p>
              </div>
            </div>

            <div className="login-box right_posterUp">
              <h2>{formTitle}</h2>
              <form>
                <div className="user-box">
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setName(e.target.value)}
                  ></input>

                  <label>Name *</label>
                </div>
                <div className="user-box">
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>

                  <label>Email</label>
                </div>
                <div className="user-box">
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setPhone(e.target.value)}
                  ></input>
                  <label>Mobile Number *</label>
                </div>
                <div className="user-box">
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setAddress(e.target.value)}
                  ></input>
                  <label>Address *</label>
                </div>
                <div className="btn_box">
                  <a className="loginButton" href="#" onClick={sendEmail}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EstimateForm;
