import React from "react";
import googlelogo from "./images/google.png";
import starlogo from "./images/icon-stars.png";
import "./reviews.css";

const Reviews = () => {
  return (
    <section className="section6">
      <div className="section6_subDiv6">
        <div className="heading">
          <h3>
            Our Oklahoma City Roofing & Construction <br></br>Company Reviews
          </h3>
        </div>
        <div className="google_box">
          <div className="google_box_A">
            <div className=" google_box_comon google_box_A_imgDiv">
              <img src={googlelogo}></img>
            </div>
            <div className=" google_box_comon google_box_A_starlogoDiv">
              <img src={starlogo}></img>
            </div>
            <div className=" google_box_p_comon google_box_A_p1Div">
              <p>Beau Lafferty</p>
            </div>
            <div className=" google_box_comon_home google_box_comon google_box_A_p2Div">
              <p>Homeowner</p>
            </div>
            <div className=" google_box_comon google_box_A_p3Div">
              <p>
                Had a multitude of projects done recently by Roof66. Had minor
                roof repair, siding replacement and some painting done. Work was
                competitively priced and completed in a timely and professional
                manner. Would definitely use them again and highly recommend
                their work.
              </p>
            </div>
          </div>
          <div className="google_box_A">
            <div className=" google_box_comon google_box_A_imgDiv">
              <img src={googlelogo}></img>
            </div>
            <div className=" google_box_comon google_box_A_starlogoDiv">
              <img src={starlogo}></img>
            </div>
            <div className="google_box_p_comon  google_box_A_p1Div">
              <p>Jennifer Mounce</p>
            </div>
            <div className=" google_box_comon_home google_box_comon google_box_A_p2Div">
              <p>Homeowner</p>
            </div>
            <div className=" google_box_comon google_box_A_p3Div">
              <p>
                We highly recommend Roof66 as they have nothing but the highest
                quality work. We had all of the siding of our home replaced as
                well as the gutters and roof. The before and after is
                astonishing in comparison. Their team is professional and timely
              </p>
            </div>
          </div>
          <div className="google_box_A">
            <div className=" google_box_comon  google_box_A_imgDiv">
              <img src={googlelogo}></img>
            </div>
            <div className=" google_box_comon  google_box_A_starlogoDiv">
              <img src={starlogo}></img>
            </div>
            <div className=" google_box_p_comon google_box_A_p1Div">
              <p>Joshua McBride</p>
            </div>
            <div className=" google_box_comon_home google_box_comon  google_box_A_p2Div">
              <p>Homeowner</p>
            </div>
            <div className=" google_box_comon  google_box_A_p3Div">
              <p>
                Customer focused! Fixed my leak and roof quickly and made sure I
                was informed and happy all along the way. Can’t recommend them
                enough! Not only did they replace the whole roof but fixed the
                leak problem as well as fixed other potential issues that could
                have caused problems in the future. They work for you not the
                insurance company!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
