import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import "./qna.css";

const Qna = ({
  obj,
  name,
  ind,
  setObj,
  setTick,
  className,
  editing,
  placeholder,
  onDelete = () => {},
}) => {
  const [inputText, setInputText] = useState(obj[ind][`${name}`]);
  const inputRef = useRef();
  inputRef.current = inputText;

  useEffect(() => {
    if (editing)
      setTimeout(() => {
        if (inputRef.current == inputText) {
          var tempArr = [...obj];
          var tempObj = { ...tempArr[ind] };
          tempObj[`${name}`] = inputText;
          tempArr[ind] = tempObj;
          setObj(tempArr);
          setTick((i) => i + 1);
        }
      }, 500);
  }, [inputText]);

  useEffect(() => {
    if (obj[`${name}`] != inputText) setInputText(obj[ind][`${name}`]);
  }, [obj]);

  const deleteHandler = () => {
    var tempArr = [...obj];
    tempArr.splice(ind, 1);
    setObj(tempArr);
    setTick((i) => i + 1);
    onDelete(tempArr);
  };

  return (
    <div id="qna">
      {(name == "ques" || name == "title") && (
        <div className="row">
          <p className="subTitle">
            {name == "ques" ? "Ques" : "Service"} {ind + 1}
          </p>
          {editing && (
            <Button
              variant="contained"
              color="warning"
              onClick={deleteHandler}
              style={{ marginLeft: 10, marginTop: -8 }}
              size="small"
            >
              Delete
            </Button>
          )}
        </div>
      )}
      {editing ? (
        <textarea
          style={{ backgroundColor: "#E7E6E4", marginBottom: 5 }}
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
          className="textInput"
          placeholder={placeholder || "Enter text here"}
          name="bannerUrl"
        />
      ) : (
        <p className={className}>{obj[ind][`${name}`]}</p>
      )}
    </div>
  );
};

export default Qna;
