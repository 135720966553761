import React, { useContext } from "react";
import InfoBar from "../../components/infoBar/infoBar";
import Navbar from "../../components/navbar/navbar";
import Intro from "../../components/intro/intro";
import PhotoSection from "./photoSection";
import GetEstimate from "../../components/getEstimate/getEstimate";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer/footer";
import { UserContext } from "../../context/User";

function Projects() {
  const { projectData } = useContext(UserContext);
  const { sectionOne, sectionTwo } = projectData;
  return (
    <div>
      <InfoBar />
      <Navbar />
      <Intro
        title={sectionOne?.title}
        subtitle={sectionOne?.text}
        img={sectionOne?.bannerUrl}
      />
      <PhotoSection photoArr={sectionTwo} />
      <GetEstimate />
      <Footer />
      <Copyright />
    </div>
  );
}

export default Projects;
