import React, { useContext, useEffect } from "react";
import InfoBar from "../../../components/infoBar/infoBar";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";
import BotomFooter from "../../../components/getEstimate/getEstimate";
import Copyright from "../../../components/copyright/copyright";
import EstimateForm from "../../../components/estimateForm/estimateForm";
import ExtraInfo from "../../../components/ExtraInfo/extraInfo";
import { UserContext } from "../../../context/User";

const ServiceDetail = ({ routeName }) => {
  const { servicesArray, serviceDetailData } = useContext(UserContext);
  const item = servicesArray.filter((i) => i.route == routeName)?.[0];
  const { sectionOne } = serviceDetailData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <InfoBar />
      <Navbar />
      <EstimateForm
        title={item.title}
        subtitle={item?.text}
        img={item?.img}
        formTitle={"Get a Free Estimate"}
      />
      <ExtraInfo data={sectionOne} />
      <BotomFooter />
      <Footer />
      <Copyright />
    </>
  );
};

export default ServiceDetail;
