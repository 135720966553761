import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";

import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const navigate = useNavigate();
  const winHeight = window.innerHeight;

  const loginHandler = async () => {
    signInWithEmailAndPassword(auth, email, pass).catch((err) =>
      alert(`err, ${err}`)
    );
  };

  return (
    <div
      style={{ flex: 1, height: winHeight * 1 }}
      className="loginContainer"
      id="login"
    >
      <div className="form">
        {/* <img
          src={require("../../../assets/images/tuturo.png")}
          height="150"
          width="300"
          className="logo"
        /> */}
        <div className="title whiteText">Sign In</div>
        <form>
          <div className="inputContainer">
            <label className="whiteText">Email </label>
            <input
              className="input"
              type="email"
              name="email"
              required
              // placeholder=" email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="inputContainer">
            <label className="whiteText">Password</label>
            <input
              className="input"
              type="text"
              name="pass"
              // placeholder=" password"
              required
              onChange={(event) => setPass(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Button
              color="inherit"
              variant="outlined"
              sx={{ color: " #434166", borderRadius: 2 }}
              onClick={loginHandler}
            >
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
