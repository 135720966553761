import React, { useContext, useState } from "react";
import InfoBar from "../../components/infoBar/infoBar";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import BotomFooter from "../../components/getEstimate/getEstimate";
import Copyright from "../../components/copyright/copyright";
import EstimateForm from "../../components/estimateForm/estimateForm";
import ExtraInfo from "../../components/ExtraInfo/extraInfo";
import { UserContext } from "../../context/User";

function Financing() {
  const { financeData } = useContext(UserContext);
  const { sectionOne, sectionTwo } = financeData;
  return (
    <>
      <InfoBar />
      <Navbar />
      <EstimateForm
        title={sectionOne?.title}
        subtitle={sectionOne?.text}
        img={sectionOne?.bannerUrl}
        formTitle={"Financing Request"}
      />
      <ExtraInfo data={sectionTwo} />
      <BotomFooter />
      <Footer />
      <Copyright />
    </>
  );
}

export default Financing;
