import React, { useContext } from "react";
import "./midSection.css";
import { UserContext } from "../../../context/User";

function MidSection() {
  const { aboutData, homeData } = useContext(UserContext);
  const { sectionTwo, sectionThree } = aboutData;

  return (
    <>
      <section className="Main_about_contanair">
        <div className="Main_about_in_contanair">
          <div className="second_section ">
            <div className="second_sub_section">
              <div className="subDiv10_B  ">
                <div className="subDiv10_B_sub ">
                  <div className="headline_top ">
                    {homeData?.sectionSix?.name}
                    <span className="line_span"></span>
                  </div>

                  <div className="heading_second_box">
                    <h2>{sectionTwo?.title}</h2>
                  </div>

                  <div className="sub10_second_para">
                    <p className="sub10_para">{sectionTwo?.text1}</p>

                    <p className="sub10_para2">{sectionTwo?.text2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom_img_box">
            <div className="bottom_img_subDiv">
              <div className="img_bottom_box box effect5">
                <img
                  className="imgbox_bottom"
                  src={sectionThree?.bannerUrl}
                ></img>
                <div className="img_cover_up"></div>
              </div>

              <div className="img_up_up_div">
                <div className="text_bottom_img_up">
                  <h1>{sectionThree?.title}</h1>
                  <span className="img_up_span">{sectionThree?.text1}</span>
                  <p>{sectionThree?.text2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MidSection;
