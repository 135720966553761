import React from 'react'
import InfoBar from '../../components/infoBar/infoBar';
import Navbar from '../../components/navbar/navbar';
import PrivacyScreen from './privacy'; 	
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer/footer";


function PrivacyPolicy() {
  return (
    <>
    <InfoBar></InfoBar>
    <Navbar></Navbar>
    <PrivacyScreen></PrivacyScreen>
    <Footer></Footer>
    <Copyright />
    
    
    
    </>
  )
}

export default  PrivacyPolicy;