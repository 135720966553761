import React, { useContext } from "react";
import GetEstimate from "../../components/getEstimate/getEstimate";
import Footer from "../../components/footer/footer";
import Copyright from "../../components/copyright/copyright";
import Navbar from "../../components/navbar/navbar";
import InfoBar from "../../components/infoBar/infoBar";
import Intro from "../../components/intro/intro";
import MidSection from "./midSection/midSection";
import { UserContext } from "../../context/User";

function About() {
  const { aboutData } = useContext(UserContext);
  const { sectionOne } = aboutData;

  return (
    <>
      <InfoBar />
      <Navbar />
      <Intro
        title={sectionOne?.title}
        subtitle={sectionOne?.text}
        img={sectionOne?.bannerUrl}
      />
      <MidSection />
      <GetEstimate />
      <Footer />
      <Copyright />
    </>
  );
}

export default About;
