import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./intro.css";

const Intro = ({ sectionOne = {}, phone = "", bannerUrl = "" }) => {
  return (
    <section
      className="section3"
      style={{ backgroundImage: `url(${bannerUrl})` }}
    >
      <div className="section3_subDiv3">
        <div className="section3_hero">
          <h1 className="section3_subDiv3_h1">{sectionOne?.title}</h1>

          <p className="subDiv3_para">{sectionOne?.text}</p>
          <button className="btn_A">GET A FREE ESTIMATE</button>
          <button className="btn_B">
            <FontAwesomeIcon icon={faPhone} />
            <span className="btnB_num">{phone}</span>
          </button>
        </div>
        {/* <div className="video-container">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/luMv2_bmOz0"
            title="Best Roofing and Construction Company in Oklahoma | McCann&#39;s Roofing"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div> */}
      </div>
    </section>
  );
};

export default Intro;
