import React from "react";
import "./customArrImage.css";
import { Edit, Close } from "@material-ui/icons";

const CustomArrImage = ({
  editing,
  obj,
  ind,
  setObj,
  setTick,
  name,
  networkUrl,
  onDelete = () => {},
}) => {
  const handleImage = (e) => {
    if (!e.target?.files?.[0]) return;
    var tempArr = [...obj];
    var tempObj = { ...tempArr[ind] };
    tempObj[`${name}`] = URL.createObjectURL(e.target.files[0]);
    tempObj[`${name}Obj`] = e.target?.files?.[0];
    tempArr[ind] = tempObj;
    setObj(tempArr);
    setTick((i) => i + 1);
  };

  const removeImage = () => {
    var tempArr = [...obj];
    var tempObj = { ...tempArr[ind] };
    tempObj[`${name}`] = networkUrl;
    delete tempObj[`${name}Obj`];
    tempArr[ind] = tempObj;
    setObj(tempArr);
    setTick((i) => i + 1);
    onDelete(ind);
  };

  return (
    <div style={{ display: "flex" }} id="customArrImage">
      <img
        src={obj[ind][`${name}`]}
        width={700}
        height={350}
        style={{ borderRadius: 10, marginBottom: 10 }}
      />
      {editing ? (
        obj[ind][`${name}Obj`] ? (
          <div className="editIconWrapper" onClick={removeImage}>
            <Close fontSize="medium" className="closeIcon" />
          </div>
        ) : (
          <>
            <input
              type="file"
              name="file"
              accept="image/*"
              onChange={handleImage}
              style={{ display: "none" }}
              id="imgPicker"
            />
            <label for="imgPicker" className="editIconWrapper">
              <Edit fontSize="medium" className="editIcon" />
            </label>
          </>
        )
      ) : null}
    </div>
  );
};

export default CustomArrImage;
