import React, { useState } from "react";
import "./adminTabs.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Button, Divider, ListItem } from "@mui/material";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { auth } from "../../firebase";

export const drawerWidth = 280;

const AdminTabs = () => {
  const navItemList = [
    { name: "Home", route: "home" },
    { name: "Services", route: "services" },
    { name: "Service Detail", route: "serviceDetail" },
    { name: "About", route: "about" },
    { name: "Projects", route: "projects" },
    { name: "Finance", route: "finance" },
    { name: "Refer", route: "refer" },
    { name: "Contact", route: "contact" },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const logoutHandler = () => {
    auth
      .signOut()
      .then(() => navigate("/"))
      .catch((err) => console.log("err", err));
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "black", flexGrow: 1 }}
          >
            Roof66 Admin
          </Typography>
          <Button
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#434166" }}
            onClick={logoutHandler}
          >
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#434166",
          },
        }}
        variant="permanent"
        anchor="left"
        className="drawer"
      >
        <Toolbar />
        <Divider />
        <List>
          {navItemList.map((item, index) => (
            <ListItem
              key={item.name}
              disablePadding
              className={
                item.route == path ? "drawerItemSelected" : "drawerItem"
              }
            >
              <ListItemButton
                onClick={() => {
                  navigate(item.route);
                }}
              >
                <ListItemText
                  primary={item.name}
                  className={
                    item.route == path
                      ? "drawerItemTextSelected"
                      : "drawerItemText"
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div
        style={{
          marginTop: 60,
          marginRight: 42,
          width: "100%",
        }}
      >
        <Outlet />
      </div>
    </Box>
  );
};
export default AdminTabs;
