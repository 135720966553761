import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/User";
import CustomImage from "../../../components/customImage/customImage";
import CustomText from "../../../components/customText/customText";
import Qna from "../../../components/qna/qna";
import CustomArrImage from "../../../components/customArrImage/customArrImage";
import "../adminTabs.css";
import { Button } from "@mui/material";
import { storage, firestoreDb } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AdminServices = () => {
  const { servicesData, refetchData } = useContext(UserContext);
  const [editing, setEditing] = useState(0);
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState([]);
  const [sectionThree, setSectionThree] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [, setTick] = useState(0);

  const id = servicesData?.id;

  useEffect(() => {
    if (servicesData?.sectionOne)
      setSectionOne({ ...servicesData?.sectionOne });
    if (servicesData?.sectionTwo?.length)
      setSectionTwo([...servicesData?.sectionTwo]);
    if (servicesData?.sectionThree)
      setSectionThree({ ...servicesData?.sectionThree });
    setTick((i) => i + 1);
  }, [servicesData]);

  const updateDocHandler = (obj) => {
    const docRef = doc(firestoreDb, "Services", id);
    updateDoc(docRef, obj)
      .then(() => {
        refetchData();
        setShowLoader(false);
        alert("Success");
      })
      .catch((err) => {
        setShowLoader(false);
        console.log("err", err);
      });
  };

  const submitHandler = (data, objKey, imgKey) => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempObj = {};
      tempObj[objKey] = data?.length ? [...data] : { ...data };

      if (imgKey && data?.[imgKey] != servicesData?.[objKey]?.[imgKey]) {
        const storageRef = ref(
          storage,
          `/services/${id}/${data?.[`${imgKey}Obj`]?.name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          data?.[`${imgKey}Obj`]
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => {
            setShowLoader(false);
            console.log(err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              tempObj[objKey][imgKey] = url;
              delete tempObj[objKey][`${imgKey}Obj`];
              updateDocHandler(tempObj);
            });
          }
        );
      } else {
        updateDocHandler(tempObj);
      }
    }
  };

  const serviceSubmitHandler = (data, ind) => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempArr = [...sectionTwo];
      var tempObj = { ...data };
      var currentData = servicesData[ind] ? { ...servicesData[ind] } : {};

      if (data?.img !== currentData?.img) {
        const storageRef = ref(
          storage,
          `/services/${id}/${data?.imgObj?.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, data?.imgObj);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => {
            setShowLoader(false);
            console.log(err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              tempObj["img"] = url;
              delete tempObj?.imgObj;
              tempArr[ind] = tempObj;

              updateDocHandler({ sectionTwo: tempArr });
            });
          }
        );
      } else {
        tempArr[ind] = tempObj;
        updateDocHandler({ sectionTwo: tempArr });
      }
    }
  };

  return (
    <div id="common">
      <div className="wrapper">
        <p className="title">Section One</p>
        <CustomImage
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="bannerUrl"
          networkUrl={servicesData?.sectionOne?.bannerUrl}
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="title"
          className="subTitle"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text"
          className="subTitle"
        />
        <Button
          variant="contained"
          color={editing == 1 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionOne, "sectionOne", "bannerUrl")
              : () => setEditing(1)
          }
        >
          {editing == 1 ? "Save" : "Edit"}
        </Button>
        {editing == 1 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionOne(servicesData?.sectionOne);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="wrapper">
        <p className="title">Section Two</p>
        {sectionTwo.map((item, ind) => (
          <div>
            <Qna
              editing={editing == 2 + ind}
              obj={sectionTwo}
              setObj={setSectionTwo}
              setTick={setTick}
              name="title"
              className="subTitle"
              ind={ind}
              placeholder="Enter title"
              onDelete={(arr) => updateDocHandler({ sectionTwo: arr })}
            />
            <CustomArrImage
              editing={editing == 2 + ind}
              obj={sectionTwo}
              setObj={setSectionTwo}
              setTick={setTick}
              name="img"
              networkUrl={item?.img}
              ind={ind}
            />
            <Qna
              editing={editing == 2 + ind}
              obj={sectionTwo}
              setObj={setSectionTwo}
              setTick={setTick}
              name="text"
              className="text"
              ind={ind}
              placeholder="Enter text"
            />
            <div style={{ marginBottom: 10 }}>
              <Button
                variant="contained"
                color={editing == 2 + ind ? "primary" : "success"}
                onClick={
                  editing
                    ? () => serviceSubmitHandler(sectionTwo[ind], ind)
                    : () => setEditing(2 + ind)
                }
              >
                {editing == 2 + ind ? "Save" : "Edit"}
              </Button>
              {editing == 2 + ind && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    setEditing(0);
                    setSectionTwo(servicesData?.sectionTwo || []);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        ))}

        <Button
          variant="contained"
          color={"primary"}
          onClick={() => {
            var tempArr = [...sectionTwo];
            tempArr.push({ title: "", text: "", img: "" });
            setSectionTwo(tempArr);
            setEditing(2 + sectionTwo.length);
          }}
          style={{ marginTop: 10 }}
        >
          Add New Service
        </Button>
      </div>

      <div className="wrapper">
        <p className="title">Section Three (service detail texts)</p>
        <CustomText
          editing={editing == 100}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="title"
          className="subTitle"
          placeholder="Enter title"
        />
        <CustomText
          editing={editing == 100}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="text1"
          className="text"
          placeholder="Enter text1"
        />
        <CustomText
          editing={editing == 100}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="text2"
          className="text"
          placeholder="Enter text2"
        />
        <CustomText
          editing={editing == 100}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="text3"
          className="text"
          placeholder="Enter text3"
        />
        <CustomText
          editing={editing == 100}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="btnText"
          className="text"
          placeholder="Enter button text"
        />
        <Button
          variant="contained"
          color={editing == 100 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionThree, "sectionThree", "")
              : () => setEditing(100)
          }
        >
          {editing == 100 ? "Save" : "Edit"}
        </Button>
        {editing == 100 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionThree(servicesData?.sectionThree);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminServices;
