import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-HtWkrgp6zOmyQxpyhUwDSnBF4NdyVs4",
  authDomain: "roof66.firebaseapp.com",
  projectId: "roof66",
  storageBucket: "roof66.appspot.com",
  messagingSenderId: "769485711814",
  appId: "1:769485711814:web:a603873e9315a6d373eac7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestoreDb = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
