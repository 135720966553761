import React from "react";
import "./photosection.css";
import pro1 from "./image/project1.jpeg";
import pro2 from "./image/pro2.jpg";
import pro3 from "./image/project3.jpeg";
import pro4 from "./image/project4.jpeg";
import pro5 from "./image/pro5.jpeg";
import pro6 from "./image/pro6.jpeg";
import pro7 from "./image/pro8.jpeg";
// import pro8 from "./image/pro9.jpeg"
import pro9 from "./image/pro10.jpeg";
import pro11 from "./image/pro12 (1).jpg";
import pro12 from "./image/pro12 (2).jpg";
import pro13 from "./image/pro12 (3).jpg";
import pro14 from "./image/pro12(4).jpg";
import pro16 from "./image/pro16 (1).jpg";
import pro17 from "./image/pro16 (2).jpg";
import pro18 from "./image/pro18.jpg";
import pro19 from "./image/pro19.jpg";
import { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

function PhotoSection({ photoArr }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = photoArr?.length ? photoArr.map((item) => item?.img) : [];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <section className="projectSection">
        <div className="projectSection-Sub">
          {images.map((src, index) => (
            <div className="smallDiv">
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                width="100%"
                height="100%"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            </div>
          ))}
        </div>
      </section>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ zIndex: 1000 }}
        />
      )}
    </>
  );
}

export default PhotoSection;
