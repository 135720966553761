import React, { useContext, useEffect, useState } from "react";
import InfoBar from "../../components/infoBar/infoBar";
import Navbar from "../../components/navbar/navbar";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer/footer";
import Intro from "../../components/intro/intro";
import Form from "../../components/form/form";
import { UserContext } from "../../context/User";

function ContactUs() {
  const { contactData } = useContext(UserContext);
  const { sectionOne, sectionTwo } = contactData;
  return (
    <>
      <InfoBar />
      <Navbar />
      <Intro
        title={sectionOne?.title}
        subtitle={sectionOne?.text}
        img={sectionOne?.bannerUrl}
      />
      <Form sectionTwo={sectionTwo} />
      <Footer />
      <Copyright />
    </>
  );
}

export default ContactUs;
