import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/home";
import About from "./screens/about";
import OurServices from "./screens/ourServices";
import Projects from "./screens/projects/index";
import Financing from "./screens/financing";
import Referus from "./screens/referUs";
import ContactUs from "./screens/contact";
import ServiceDetail from "./screens/ourServices/serviceDetail";
import PrivacyPolicy from "./screens/privacyPolicy/index";
import UserProvider, { UserContext } from "./context/User";

import AdminTabs from "./screens/admin/adminTabs";
import AdminHome from "./screens/admin/home";
import AdminServices from "./screens/admin/services";
import AdminServiceDetail from "./screens/admin/serviceDetail";
import AdminAbout from "./screens/admin/about";
import AdminProjects from "./screens/admin/projects";
import AdminFinance from "./screens/admin/finance";
import AdminRefer from "./screens/admin/refer";
import AdminContact from "./screens/admin/contact";
import Login from "./screens/admin/auth/login";
import { useContext } from "react";

const RootNav = () => {
  const { user, servicesArray } = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/services" element={<OurServices />}></Route>
      {servicesArray.map((item) => (
        <Route
          path={`/services/${item?.route}`}
          element={<ServiceDetail routeName={item?.route} />}
        ></Route>
      ))}
      <Route path="/projects" element={<Projects />}></Route>
      <Route path="/financing" element={<Financing />}></Route>
      <Route path="/referus" element={<Referus />}></Route>
      <Route path="/contact" element={<ContactUs />}></Route>
      <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
      {user ? (
        <Route path="/admin" element={<AdminTabs />}>
          <Route path="home" element={<AdminHome />} />
          <Route path="services" element={<AdminServices />} />
          <Route path="serviceDetail" element={<AdminServiceDetail />} />
          <Route path="about" element={<AdminAbout />} />
          <Route path="projects" element={<AdminProjects />} />
          <Route path="finance" element={<AdminFinance />} />
          <Route path="refer" element={<AdminRefer />} />
          <Route path="contact" element={<AdminContact />} />
        </Route>
      ) : (
        <Route path="/admin" element={<Login />} />
      )}
    </Routes>
  );
};

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <div className="App">
          <RootNav />
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
