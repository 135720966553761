import React, { useContext } from "react";
import InfoBar from "../../components/infoBar/infoBar";
import Navbar from "../../components/navbar/navbar";
import Copyright from "../../components/copyright/copyright";
import Footer from "../../components/footer/footer";
import Intro from "../../components/intro/intro";
import ReferForm from "./referForm/referForm";
import { UserContext } from "../../context/User";

function ReferUs() {
  const { referData } = useContext(UserContext);
  const { sectionOne, sectionTwo } = referData;
  return (
    <>
      <InfoBar />
      <Navbar />
      <Intro
        title={sectionOne?.title}
        subtitle={sectionOne?.text}
        img={sectionOne?.bannerUrl}
      />
      <ReferForm data={sectionTwo} />
      <Footer />
      <Copyright />
    </>
  );
}

export default ReferUs;
