import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/User";
import CustomImage from "../../../components/customImage/customImage";
import CustomText from "../../../components/customText/customText";
import Qna from "../../../components/qna/qna";
import "../adminTabs.css";
import { Button } from "@mui/material";
import { storage, firestoreDb } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AdminAbout = () => {
  const { aboutData, refetchData } = useContext(UserContext);
  const [editing, setEditing] = useState(0);
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState({});
  const [sectionThree, setSectionThree] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [, setTick] = useState(0);

  useEffect(() => {
    if (aboutData?.sectionOne) setSectionOne({ ...aboutData?.sectionOne });
    if (aboutData?.sectionTwo) setSectionTwo({ ...aboutData?.sectionTwo });
    if (aboutData?.sectionThree)
      setSectionThree({ ...aboutData?.sectionThree });
    // if (aboutData?.sectionSix) setSectionSix({ ...aboutData?.sectionSix });
    setTick((i) => i + 1);
  }, [aboutData]);

  const submitHandler = (data, objKey, imgKey) => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempObj = {};
      tempObj[objKey] = data?.length ? [...data] : { ...data };
      const id = aboutData?.id;
      const docRef = doc(firestoreDb, "About", id);
      if (imgKey && data?.[imgKey] != aboutData?.[objKey]?.[imgKey]) {
        const storageRef = ref(
          storage,
          `/about/${id}/${data?.[`${imgKey}Obj`]?.name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          data?.[`${imgKey}Obj`]
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => {
            setShowLoader(false);
            console.log(err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              tempObj[objKey][imgKey] = url;
              delete tempObj[objKey][`${imgKey}Obj`];
              updateDoc(docRef, tempObj)
                .then(() => {
                  setShowLoader(false);
                  refetchData();
                  alert("Success");
                })
                .catch((err) => {
                  setShowLoader(false);
                  console.log("err", err);
                });
            });
          }
        );
      } else {
        updateDoc(docRef, tempObj)
          .then(() => {
            refetchData();
            setShowLoader(false);
            alert("Success");
          })
          .catch((err) => {
            setShowLoader(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div id="common">
      <div className="wrapper">
        <p className="title">Section One</p>
        <CustomImage
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="bannerUrl"
          networkUrl={aboutData?.sectionOne?.bannerUrl}
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="title"
          className="subTitle"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text"
          className="subTitle"
        />
        <Button
          variant="contained"
          color={editing == 1 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionOne, "sectionOne", "bannerUrl")
              : () => setEditing(1)
          }
        >
          {editing == 1 ? "Save" : "Edit"}
        </Button>
        {editing == 1 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionOne(aboutData?.sectionOne);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="wrapper">
        <p className="title">Section Two</p>
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="title"
          className="subTitle"
        />
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="text1"
          className="subTitle"
        />
        <CustomText
          editing={editing == 2}
          obj={sectionTwo}
          setObj={setSectionTwo}
          setTick={setTick}
          name="text2"
          className="subTitle"
        />
        <Button
          variant="contained"
          color={editing == 2 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionTwo, "sectionTwo", "bannerUrl")
              : () => setEditing(2)
          }
        >
          {editing == 2 ? "Save" : "Edit"}
        </Button>
        {editing == 2 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionTwo(aboutData?.sectionTwo);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="wrapper">
        <p className="title">Section Three</p>
        <CustomImage
          editing={editing == 3}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="bannerUrl"
          networkUrl={aboutData?.sectionThree?.bannerUrl}
        />
        <CustomText
          editing={editing == 3}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="title"
          className="subTitle"
        />
        <CustomText
          editing={editing == 3}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="text1"
          className="subTitle"
        />
        <CustomText
          editing={editing == 3}
          obj={sectionThree}
          setObj={setSectionThree}
          setTick={setTick}
          name="text2"
          className="subTitle"
        />
        <Button
          variant="contained"
          color={editing == 3 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionThree, "sectionThree", "bannerUrl")
              : () => setEditing(3)
          }
        >
          {editing == 3 ? "Save" : "Edit"}
        </Button>
        {editing == 3 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionThree(aboutData?.sectionThree);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminAbout;
