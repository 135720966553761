import React, { useRef, useState } from "react";
import "./form.css";
import { sendEmailHelper } from "../../helpers";

function Form({ sectionTwo = {} }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [service, setService] = useState("Roofing");

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name || !phone || !address || !service) return alert("Fill details");
    var data = {
      from_name: "random name",
      from_email: "randomemail@gmail.com",
      name,
      phone,
      email,
      address,
      service,
    };
    sendEmailHelper("template_z3yipzj", data);
  };

  return (
    <section className="section5">
      <div className="section5_subDiv5">
        <div className="section5_subDiv5_sub">
          <div className="section5_left">
            <div className="section5_left_heading">
              <h2>{sectionTwo?.title}</h2>
            </div>
            <div className="section5_left_para">
              <p className="section5-heading-title">{sectionTwo?.subtitle}</p>
              <p className="elementor-clearfix">{sectionTwo?.text1}</p>
              <p className="elementor-clearfix">{sectionTwo?.text2}</p>
            </div>
          </div>
          <div className="section5_right">
            <div className="section5_right_heading">
              <p className="input_top_heading">Free estimate, no obligation.</p>
            </div>
            <form>
              <div className="section5_right_form">
                <label>
                  <input
                    type="text"
                    placeholder="Full Name *"
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="text"
                    placeholder="Phone number *"
                    onChange={(e) => setPhone(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="email"
                    placeholder="You Email"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="text"
                    placeholder="Address *"
                    onChange={(e) => setAddress(e.target.value)}
                  ></input>
                </label>
                <div className="select_service_div">
                  <span className="select_service_span">Select Service *</span>
                  <select
                    className="option"
                    onChange={(e) => setService(e.target.value)}
                  >
                    <option value="Roofing">Roofing</option>
                    <option value="Storm Damage">Storm Damage</option>
                    <option value="Construction">Construction</option>
                    <option value="Skylights">Skylights</option>
                    <option value="Windows and Doors">Windows and Doors</option>
                    <option value="Sliding">Sliding</option>
                    <option value="Painting">Painting</option>
                    <option value="Custom Patios">Custom Patios</option>
                    <option value="Virtual Design Center">
                      Virtual Design Center
                    </option>
                  </select>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn_submit"
                    onClick={sendEmail}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
