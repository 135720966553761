import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/User";
import CustomText from "../../../components/customText/customText";
import CustomProjImage from "../../../components/customProjImage/customProjImage";
import "../adminTabs.css";
import { Button } from "@mui/material";
import { storage, firestoreDb } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AdminServiceDetail = () => {
  const { serviceDetailData, refetchData } = useContext(UserContext);
  const [editing, setEditing] = useState(0);
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [, setTick] = useState(0);

  const id = serviceDetailData?.id;

  useEffect(() => {
    if (serviceDetailData?.sectionOne)
      setSectionOne({ ...serviceDetailData?.sectionOne });
    if (serviceDetailData?.sectionTwo?.length)
      setSectionTwo([...serviceDetailData?.sectionTwo]);
    setTick((i) => i + 1);
  }, [serviceDetailData]);

  const updateDocHandler = (obj) => {
    const docRef = doc(firestoreDb, "ServiceDetail", id);
    updateDoc(docRef, obj)
      .then(() => {
        refetchData();
        setShowLoader(false);
        alert("Success");
      })
      .catch((err) => {
        setShowLoader(false);
        console.log("err", err);
      });
  };

  const submitHandler = (data, objKey, imgKey) => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempObj = {};
      tempObj[objKey] = data?.length ? [...data] : { ...data };

      if (imgKey && data?.[imgKey] != serviceDetailData?.[objKey]?.[imgKey]) {
        const storageRef = ref(
          storage,
          `/serviceDetail/${id}/${data?.[`${imgKey}Obj`]?.name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          data?.[`${imgKey}Obj`]
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (err) => {
            setShowLoader(false);
            console.log(err);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              tempObj[objKey][imgKey] = url;
              delete tempObj[objKey][`${imgKey}Obj`];
              updateDocHandler(tempObj);
            });
          }
        );
      } else {
        updateDocHandler(tempObj);
      }
    }
  };

  const submitImageHandler = () => {
    if (showLoader) return;
    else {
      setEditing(0);
      setShowLoader(true);
      var tempArr = [];
      sectionTwo.map((item, ind) => {
        if (item?.imgObj) {
          const storageRef = ref(
            storage,
            `/serviceDetail/${id}/${item?.imgObj?.name}`
          );
          const uploadTask = uploadBytesResumable(storageRef, item?.imgObj);
          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {
              setShowLoader(false);
              console.log(err);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                tempArr.push({ img: url });
                if (tempArr.length == sectionTwo.length) {
                  updateDocHandler({ sectionTwo: tempArr });
                }
              });
            }
          );
        } else {
          tempArr.push(item);
          if (tempArr.length == sectionTwo.length) {
            updateDocHandler({ sectionTwo: tempArr });
          }
        }
      });
    }
  };

  return (
    <div id="common">
      <div className="wrapper">
        <p className="title">Section One (check services tab) </p>
      </div>

      <div className="wrapper">
        <p className="title">Section Two </p>
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="title"
          className="subTitle"
          placeholder="Enter title"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text1"
          className="text"
          placeholder="Enter text1"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text2"
          className="text"
          placeholder="Enter text2"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="text3"
          className="text"
          placeholder="Enter text3"
        />
        <CustomText
          editing={editing == 1}
          obj={sectionOne}
          setObj={setSectionOne}
          setTick={setTick}
          name="btnText"
          className="text"
          placeholder="Enter button text"
        />
        <Button
          variant="contained"
          color={editing == 1 ? "primary" : "success"}
          onClick={
            editing
              ? () => submitHandler(sectionOne, "sectionOne", "")
              : () => setEditing(1)
          }
        >
          {editing == 1 ? "Save" : "Edit"}
        </Button>
        {editing == 1 && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setEditing(0);
              setSectionOne(serviceDetailData?.sectionOne);
            }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="wrapper">
        <p className="title">Section Three</p>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {sectionTwo.map((item, index) => (
            <CustomProjImage
              editing={editing == 2}
              obj={sectionTwo}
              setObj={setSectionTwo}
              setTick={setTick}
              name="img"
              networkUrl={item?.img}
              ind={index}
              onDelete={(i) => {
                var tempArr = [...sectionTwo];
                tempArr.splice(i, 1);
                setSectionTwo(tempArr);
                setTick((val) => val + 1);
              }}
            />
          ))}
        </div>

        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            var tempArr = [...sectionTwo];
            tempArr.push({ img: "" });
            setSectionTwo(tempArr);
            setEditing(2);
          }}
        >
          Add New
        </Button>
        {editing == 2 && (
          <>
            {!!sectionTwo?.length && (
              <Button
                variant="contained"
                color={"primary"}
                onClick={submitImageHandler}
                style={{ marginLeft: 10 }}
              >
                Save
              </Button>
            )}
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setEditing(0);
                setSectionTwo(serviceDetailData?.sectionTwo || []);
              }}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminServiceDetail;
