import React, { useState } from "react";
import "./referForm.css";
import { sendEmailHelper } from "../../../helpers";

function ReferForm({ data }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [friendName, setFriendName] = useState("");
  const [friendPhone, setFriendPhone] = useState("");
  const [friendEmail, setFriendEmail] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name || !phone || !friendName || !friendPhone)
      return alert("Fill details");
    var data = {
      from_name: "random name",
      from_email: "randomemail@gmail.com",
      name,
      phone,
      email,
      friendName,
      friendPhone,
      friendEmail,
    };
    sendEmailHelper("template_35mxdjw", data);
  };

  return (
    <section className="section5">
      {" "}
      {/* width 100% */}
      <div className="section5_subDiv5">
        {" "}
        {/* width 80% */}
        <div className="section5_subDiv5_sub">
          {" "}
          {/* width 80% 1200px*/}
          <div className="section5_left">
            {" "}
            {/*  570px*/}
            <div className="section5_left_heading">
              <h2>{data?.title}</h2>
            </div>
            <div className="section5_left_para">
              <p className="section5-heading-title">{data?.text1}</p>
              <p className="elementor-clearfix">{data?.text2}</p>
              <p className="elementor-clearfix">{data?.text3}</p>
            </div>
          </div>
          <div className="section5_right">
            {/* 630px*/}
            <div className="section5_right_heading">
              <p className="input_top_heading">Referral Form.</p>
            </div>
            <form>
              <div className="section5_right_form">
                <label>
                  <input
                    type="text"
                    placeholder="Your Name *"
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="text"
                    placeholder="Your Phone number *"
                    onChange={(e) => setPhone(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="email"
                    placeholder="Your Email"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="text"
                    placeholder="Referral's Name *"
                    onChange={(e) => setFriendName(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="text"
                    placeholder="Referral's Phone number *"
                    onChange={(e) => setFriendPhone(e.target.value)}
                  ></input>
                </label>
                <label>
                  <input
                    type="email"
                    placeholder="Referral's Email"
                    onChange={(e) => setFriendEmail(e.target.value)}
                  ></input>
                </label>
                <div>
                  <button
                    type="submit"
                    className="btn_submit"
                    onClick={sendEmail}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReferForm;
